import moment from 'moment';

export default (invoice, subscription) => {
  if (moment(invoice.servicePeriod.serviceEndDate, 'YYYY-MM-DD').isBefore(moment())) {
    // this paid invoice is for previous period, show generated preview date and amount
    return {
      i18nkey: 'membership:memberships.past-due-renewal',
      date: moment(invoice.servicePeriod.serviceEndDate).add(1, 'd'),
      amount: subscription.nextPaymentAmountNumber(invoice.servicePeriod.serviceEndDate),
    };
  }

  // paid invoice for current period, show next renew date
  return {
    i18nkey: 'membership:memberships.next-renewal',
    // date: moment(invoice.servicePeriod.serviceEndDate).add(1, 'd'),
    date: moment(subscription.termEndDate),
    amount: subscription.nextPaymentAmountNumber(invoice.servicePeriod.serviceEndDate),
  };
};
