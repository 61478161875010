export const CREDIT_CARDS = {
  visa: {
    name: 'Visa',
    image: '/static/images/cards/gfx-card-visa.svg',
  },
  mastercard: {
    name: 'Master Card',
    image: '/static/images/cards/gfx-card-master-card.svg',
  },
  americanexpress: {
    name: 'American Express',
    image: '/static/images/cards/gfx-card-am-ex.svg',
  },
  amex: {
    name: 'American Express',
    image: '/static/images/cards/gfx-card-am-ex.svg',
  },
  discover: {
    name: 'Discover',
    image: '/static/images/cards/gfx-card-discover.svg',
  },
  dinersclub: {
    name: 'Diners Club',
    image: '/static/images/cards/logo-diners.png',
  },
  jcb: {
    name: 'JCB',
    image: '/static/images/cards/logo-jcb.png',
  },
  sepa: {
    name: 'SEPA',
    image: '/static/images/cards/sepa.svg',
  },
};

export default CREDIT_CARDS;
