import forge from 'node-forge';
import { getCountryByValue } from 'constants/countries';
import { States } from 'utils/states';
import { ZUORA_RENDER_URL, REACT_APP_PREFILL_CREDIT_CARD, ZUORA_PAYMENT_GATEWAY } from 'env';
/* global Z */

export class ZuoraFrameService {
  /**
   *
   * @param checkout
   * @return {{firstName: string, lastName: string}}
   * @private
   */
  static _getFirstAndLastName(checkout) {
    const {
      billingFirstName, billingLastName, firstName, lastName,
    } = checkout;

    if (billingFirstName && billingLastName) {
      return {
        firstName: billingFirstName,
        lastName: billingLastName,
      };
    }

    return { firstName, lastName };
  }

  /**
   * @typedef {object} ZuoraCallbackResponse
   * @property {boolean|string} success
   * @property {string} redirectUrl
   * @property {string} refId
   * @property {string} signature
   * @property {string} token
   * @property {string} [errorMessage]
   */

  /**
   * DEPRECATED
   * @param {ZuoraCallbackResponse} response
   * @return {{url: string|null, err: string}}
   */
  static buildCallbackUrlFromZuoraCallback(response) {
    const {
      success, redirectUrl, refId, signature, token, errorMessage,
    } = response;

    if (!success || success === 'false') {
      return { err: 'Error when performing the request', url: null };
    }
    return {
      url: `${redirectUrl}?refId=${refId}&success=${success}&signature=${signature}&token=${token}`,
      err: errorMessage || null,
    };
  }

  static buildZuoraErrorMsg(key, code, message, t) {
    console.dir({
      key, code, message,
    });
    const errorMessage = message;

    if (key === 'creditCardNumber') {
      if (code === '001') {
        return t('checkout:billing.errors.Required', 'Required');
      }
      if (code === '002') {
        return t('checkout:billing.errors.Number does not match credit card Please try again', 'Number does not match credit card. Please try again.');
      }
    }

    if (message && message.includes('Expiration date must be a future date')) {
      return t('checkout:billing.errors.Expiration date must be a future date', 'Expiration date must be a future date');
    }

    if (message && message.includes('Your card was declined. Your request was in live mode, but used a known test card')) {
      return 'Your card was declined. Your request was in live mode, but used a known test card';
    }

    if (message && message.includes('Your bank declined your payment. Please try another card')) {
      return t(
        'checkout:billing.errors.Your bank declined your payment Please try another card or contact your bank',
        'Your bank declined your payment. Please try another card or contact your bank',
      );
    }

    if (message && message.includes('Too many submissions')) {
      return t(
        'checkout:billing.errors.Too many submissions Please retry later',
        'Too many submissions. Please retry later',
      );
    }

    if (message && message.includes('The provided IBAN is not a valid')) {
      return t(
        'checkout:billing.errors.invalid-iban',
        'The provided IBAN is not a valid one',
      );
    }

    if (message && message.includes('Required Field Not Completed')) {
      return t(
        'membership:memberships.errors.required-field-not-completed',
        'Required Field Not Completed',
      );
    }

    if (key === 'error' && message.indexOf('[GatewayTransactionError]&nbsp;') !== -1) {
      console.log('inside');
      const regex = /(.* - )(.*)/;
      const regex2 = /(.*:)(.*)/;
      const regex3 = /(.*])(.*)/;

      const replaceMessage = t(`checkout:billing.errors.${message
        .replace(regex, '$2')
        .replace(regex2, '$1')
        .replace(regex3, '$2')
        .trim()
        .replace(/[:.']/g, '')}`, message);
      return replaceMessage;
    }

    if (key === 'error') {
      const regex = /(.* - )(.*)/;
      const replaceMessage = t(`checkout:billing.errors.${message.replace(regex, '$2').replace(/\./g, '').replace("'", '')}`, message);
      return replaceMessage;
    }

    return errorMessage;
  }

  static encryptFieldValue(value) {
    const publicKeySource = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAiKrAfeD2SY0SyYWvjgRmjoPE2tOFiN5ZDdPQXNK415SIUE7i1RrLTNMsCFx+wIW/w1n4n3o5x//yajt6e/MSAEXpzdBcf13K4ssqnT80LRM0n1MATSc0rUHeY1IygNmJlAs7XH92rkXrxAsj/hd0gHih6sLaRFqCahbX8eYeuFEbFmFti1/CW+iAuNXCsQeNYid7XSHrVWgy6JaUZAV0j5cH9ttKGRjZwCBDwDxX063yMqVJFTM5tK3V3Oe2JxGOGIoknT+Fj2lA9nbAalUR0w8yA5GCGyqmO4RkytnlcAQFNe8kww8Td2JoMK91Aj1OAH2s7nRtyJO293GYCZfvKwIDAQAB';
    const pkstr = `-----BEGIN PUBLIC KEY-----\n${publicKeySource}-----END PUBLIC KEY-----\n`;
    const publicKey = forge.pki.publicKeyFromPem(pkstr);
    return btoa(publicKey.encrypt(value));
  }

  static getCreditCardContact(checkout) {
    const { firstName, lastName } = ZuoraFrameService._getFirstAndLastName(checkout);
    let cardHolderName = `${firstName} ${lastName}`;
    if (`${firstName} ${lastName}`.length >= 49) {
      cardHolderName = `${firstName} ${lastName}`.slice(0, 49).trim();
    }
    const country = getCountryByValue(checkout.billingCountryRegion || checkout.country);
    const state = checkout.billingState || checkout.state;
    const countryState = States.getStateByCountryAndAbbreviation(country, state);
    return {
      creditCardHolderName: cardHolderName,
      creditCardAddress1: checkout.billingAddress || checkout.address,
      creditCardCountry: (country && country.alpha3) || checkout.country,
      creditCardState: countryState.name || checkout.state,
      creditCardCity: checkout.billingCity || checkout.city,
      creditCardPostalCode: checkout.billingPostalCode || checkout.postalCode,
      phone: checkout.phone,
      email: checkout.email,
    };
  }

  static updateZuoraParams(checkout) {
    const {
      postalCode,
      billingPostalCode,
      isBillingAddressSame,
      firstName,
      lastName,
      country,
    } = checkout;

    if (country === 'be') return null;

    try {
      if (firstName || lastName) {
        let cardHolderName = `${firstName} ${lastName}`;
        if (`${firstName} ${lastName}`.length >= 49) {
          cardHolderName = `${firstName} ${lastName}`.slice(0, 49).trim();
        }
        Z.post('z_hppm_iframe', `setField(creditCardHolderName:${cardHolderName})`);
      }
      if (!isBillingAddressSame) {
        Z.post('z_hppm_iframe', `setField(creditCardPostalCode:${billingPostalCode})`);
      } else {
        Z.post('z_hppm_iframe', `setField(creditCardPostalCode:${postalCode})`);
      }
    } catch (e) {
      if (e.message === "Cannot read property 'src' of null") {
        // known error of using Z.post method
      } else {
        console.error(e);
      }
    }
    return null;
  }

  static buildZuoraRenderArguments(checkout, pageData) {
    const { country } = checkout;
    const locales = {
      us: 'en_US',
      uk: 'en_GB',
      ch: 'de_CH',
      be: 'fr_BE',
      de: 'de_DE',
    };

    const params = {
      tenantId: pageData.tenantId,
      id: pageData.pageId,
      token: pageData.token,
      signature: pageData.signature,
      key: pageData.key,
      style: 'inline',
      submitEnabled: 'true',
      url: ZUORA_RENDER_URL,
      locale: locales[checkout.country],
      paymentGateway: ZUORA_PAYMENT_GATEWAY[country || 'us'],
    };

    let prepopulateEncrypted = {};

    if (REACT_APP_PREFILL_CREDIT_CARD === true) {
      prepopulateEncrypted = {
        creditCardNumber: this.encryptFieldValue('4111111111111111'),
        creditCardExpirationMonth: this.encryptFieldValue('06'),
        creditCardExpirationYear: this.encryptFieldValue('2030'),
        cardSecurityCode: this.encryptFieldValue('123'),
      };
    }

    const prepopulateFields = {
      ...ZuoraFrameService.getCreditCardContact(checkout),
      ...prepopulateEncrypted,
    };

    return { params, prepopulateFields };
  }
}
