export function currency(amount, country = 'us', trailingZeros = true) {
  let format = parseFloat(amount).toFixed(2);
  if (country === 'be') { // further we'll add country where comma used for decimals
    format = format.replace('.', ',');
  }
  if (!trailingZeros) {
    format = format.replace(/[.,]00$/, '');
  }
  return format;
}
