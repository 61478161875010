import React from 'react';
import cx from 'classnames';
import AddNewPayment from '../add-new-payment';
import styles from './styles.css';
import Spinner from '../../common/spinner';
import { useTranslation } from '../../../server/i18n';

export const AddNewPaymentMethodLayout = ({
  activeTab, handleSetActiveTab, country, ...rest
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {country === 'be' && (
        <div className={styles.btnContainer}>
          <button
            className={cx({
              [styles.isActive]: activeTab === 'stripe',
              [styles.isDisabled]: activeTab === 'sepa',
            })}
            onClick={() => handleSetActiveTab('stripe')}
          >
            <span>
              {t('common:payment-methods.credit-card', 'Credit card')}
            </span>
          </button>
          <button
            className={cx({
              [styles.isActive]: activeTab === 'sepa',
              [styles.isDisabled]: activeTab === 'stripe',
            })}
            onClick={() => handleSetActiveTab('sepa')}
          >
            <span>
              {t('common:payment-methods.sepa', 'SEPA')}
            </span>
          </button>
        </div>
      )}
      <div className={styles.paymentMethodsContainer}>
        <AddNewPayment
          {...rest}
          activeTab={activeTab}
        />
        {rest.zouraHpmLoaded && (
          <Spinner className={cx(styles.spinner)} />
        )}
      </div>
    </div>
  );
};
