import { Component } from 'react';

class AddressFormLayout extends Component {
  constructor(props) {
    super(props);
    if (this.constructor === AddressFormLayout) {
      throw new Error('AddressFormLayout is an abstract class');
    }
    this.account = this.props.mobxStore.accountStore.localAccount;
  }

  get fieldDisabled() {
    return this.account.updating ? 'disabled' : '';
  }

  get submitDisabled() {
    return this.form.validating
      || !this.form.isValid
      || this.form.isPristine ? 'disabled' : '';
  }
}

export default AddressFormLayout;
