import moment from 'moment';

export default (invoice) => {
  if (moment(invoice.servicePeriod.serviceStartDate, 'YYYY-MM-DD').isAfter(moment())) {
    // unlikely case
    // generated invoice for the future, show invoice data, but not cast as past due
    return {
      i18nkey: 'membership:memberships.next-renewal',
      date: moment(invoice.servicePeriod.serviceStartDate),
      amount: invoice.balance,
    };
  }

  // not paid invoice, show dates and amount from invoice
  return {
    i18nkey: 'membership:memberships.past-due-renewal',
    date: moment(invoice.servicePeriod.serviceStartDate),
    amount: invoice.balance,
  };
};
