import React, { Fragment } from 'react';

export const getName = (a) => {
  if (a.FirstName) {
    return (
      <Fragment>
        {a.FirstName}
        &nbsp;
        {a.LastName}
        {
          (a.FirstName || a.LastName) && (<br />)
        }
      </Fragment>
    );
  }
  return (
    <Fragment>
      {a.LastName}
      {
        a.LastName && (<br />)
      }
    </Fragment>
  );
};
