/* eslint-disable class-methods-use-this */
import { Form } from 'mobx-react-form';
import { store } from 'store';

export default class AddressForm extends Form {
  constructor(props, opts) {
    super(props, opts);
    if (this.constructor === AddressForm) {
      throw new Error('AddressForm is an abstract class');
    }
  }

  options() {
    return {
      validateOnBlur: true,
      validateOnChange: true,
      validationDebounceWait: 500,
      validationDebounceOptions: {
        leading: false,
        trailing: true,
      },
    };
  }

  hooks() {
    return {
      async onSuccess(form) {
        const {
          accountStore: {
            localAccount: {
              postAddressAdd,
            },
          },
        } = store;

        await postAddressAdd(form.values());
      },

      onError(form) {
        form.invalidate();
      },
    };
  }
}
