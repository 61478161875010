export const initGoogleAPIsService = (apiKey, callback) => {
  if (process.browser) {
    if (!window.google) { // Only load if it doesn't exist
      const s = document.createElement('script');
      s.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places,geocoder`;
      s.onload = () => callback();
      s.id = 'googlePlaces';
      document.body.appendChild(s);
    } else {
      callback();
    }
  }
};
