import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { ZUORA_RENDER_URL, ZUORA_PAYMENT_GATEWAY_LIST } from 'env';
import { withTranslation } from 'server/i18n';

import { fetchZuoraRenderParams } from 'api';
import { ZuoraFrameService } from 'utils/zuora';
import cx from 'classnames';
import './style.css';


const buildZuoraRenderArguments = ({
  pageData,
  accId,
  country,
  activeTab,
  email,
  postfix,
}) => {
  const locales = {
    us: 'en_US',
    uk: 'en_GB',
    ch: 'de_CH',
    be: 'fr_BE',
    de: 'de_DE',
  };

  const params = {
    tenantId: pageData[activeTab] ? pageData[activeTab].tenantId : pageData.tenantId,
    id: pageData[activeTab] ? pageData[activeTab].pageId : pageData.pageId,
    token: pageData[activeTab] ? pageData[activeTab].token : pageData.token,
    signature: pageData[activeTab] ? pageData[activeTab].signature : pageData.signature,
    key: pageData[activeTab] ? pageData[activeTab].key : pageData.key,
    style: 'inline',
    submitEnabled: 'true',
    url: ZUORA_RENDER_URL,
    locale: locales[country],
    paymentGateway: ZUORA_PAYMENT_GATEWAY_LIST[activeTab][country + postfix],
    field_accountId: accId,
    authorizationAmount: 1,
  };

  const prepopulateFields = activeTab === 'stripe' ? {
    creditCardAddress1: '', // contactInfo.address,
    creditCardCountry: '', // contactInfo.country,
    // `${contactInfo.firstName} ${contactInfo.lastName}
    creditCardHolderName: '',
    creditCardState: '', // contactInfo.state,
    creditCardCity: '', // contactInfo.city,
    creditCardPostalCode: '', // contactInfo.zipCode,
    phone: '', // contactInfo.phone,
    email: '', // contactInfo.email
  } : {
    email,
  };

  return { params, prepopulateFields };
};

@withTranslation('membership')
@inject('mobxStore')
@observer
class AddNewPayment extends Component {
  constructor(props) {
    super(props);
    this.account = this.props.mobxStore.accountStore.localAccount;
  }

  componentDidMount() {
    this.renderZuoraFrame();
  }

  componentDidUpdate(nextProps) {
    if (nextProps.activeTab !== this.props.activeTab) {
      this.renderZuoraFrame();
    }
  }

  onZuoraCallback = (response) => {
    const { err } = ZuoraFrameService
      .buildCallbackUrlFromZuoraCallback(response);
    const {
      zuoraAddNewPaymentMethodSubmit,
    } = this.account;
    if (err) {
      console.error(err);
      return;
    }

    if (!response.refId) {
      console.error('No response id from Zuora');
      return;
    }

    zuoraAddNewPaymentMethodSubmit(response.refId, this.props.makeDefault, this.props.activeTab)
      .then(() => {
        this.props.finished(null, response.refId);
      })
      .catch((error) => {
        this.props.handleChangeSubmitStatus(false);
        this.props.finished(error);
        console.error(error);
      });
  }

  handleZuoraErrorMsgCallback = (key, code, message) => {
    if (message && this.props.onError) {
      this.props.handleChangeSubmitStatus(false);
      this.props.onError(message);
    }
    const errorMessage = ZuoraFrameService
      .buildZuoraErrorMsg(key, code, message, this.props.t);

    Z.sendErrorMessageToHpm(key, errorMessage);
  }

  renderZuoraFrame() {
    const {
      accountData: {
        acc_id: accId,
        basicInfo: {
          Login_Email__c: email,
          OriginCountry__c: country,
        },
      },
      isVMCustomer,
    } = this.props.mobxStore.accountStore.localAccount;
    this.props.isLoaded(true);
    fetchZuoraRenderParams(country)
      .then((data) => {
        const {
          params,
          prepopulateFields,
        } = buildZuoraRenderArguments({
          pageData: data,
          accId,
          country,
          activeTab: this.props.activeTab,
          email,
          postfix: isVMCustomer ? 'vm3' : '',
        });
        Z.renderWithErrorHandler(
          params,
          prepopulateFields,
          this.onZuoraCallback,
          this.handleZuoraErrorMsgCallback,
        );
        this.props.isLoaded(false);
      })
      .catch((err) => {
        console.error(err);
        this.props.isLoaded(false);
      });
  }

  render() {
    return (
      <div id="paymentMethodsForm">
        <div
          id="zuora_payment"
          className={cx({
            zuora_payment_sepa: this.props.activeTab === 'sepa' && this.props.isSubmitted,
          })}
        />
      </div>
    );
  }
}

export default AddNewPayment;
