import ImmediateUpgradeModal from '../immediate-upgrade-modal';

export default (diff) => {
  if (diff < -10000) {
    return {
      membershipStatus: { key: 'membership:memberships.renew.membership-status-upgrading' },
    };
  }

  return {
    membershipStatus: { key: 'membership:memberships.renew.membership-status-basic' },
    description: 'membership:memberships.renew.membership-status-basic-description-1',
    action: ImmediateUpgradeModal, // switch to UPGRADE scenario
    actionLabel: 'membership:memberships.renew.membership-status-basic-action-label',
  };
};
