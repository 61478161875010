import React from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { useTranslation } from 'server/i18n';
import css from './style.css';

export default observer(({
  field,
  disabled,
  readonly,
  notes,
  isLowercase,
  ...props
}) => {
  const { t } = useTranslation();
  const label = t(field.label, field.label);
  const optional = t('forms:common.optional', 'optional');
  const error = field.error === null || field.error === undefined || field.error === '' ? field.error : field.error.replace(field.label, label);
  const tError = error && t(`forms:errors.${error.replace('.', '')}`, error);

  return (
    <div className={
    cx({
      [css['input-text']]: true,
      [css['input-text-required']]: field.rules.includes('required'),
      [css['input-text-hasError']]: field.error,
      [css['input-text-Success']]: field.isValid && field.value !== '' && field.value !== null,
    })}
    >
      <label htmlFor={field.id}>
        <span
          data-content={` (${notes || optional})`}
          className={cx(css['input-text-label'], {
            [css.lowerCase]: isLowercase,
          })}
        >
          { label }
        </span>
        <input
          {...props}
          {...field.bind({ label })}
          disabled={disabled}
          readOnly={readonly}
        />
        {field.error && (
        <span id={`${props.id}__errorMessage`} className={css['input-text-error']}>{tError || error}</span>
        )}
      </label>
    </div>
  );
});
