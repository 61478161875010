import React from 'react';

const IconArrowDown = ({ strokeColor }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="14"
    viewBox="0 0 25 14"
  >
    <path
      fill="none"
      fillRule="evenodd"
      stroke={strokeColor || '#C6C6C6'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M23 1L12.27 12 2 1.472"
    />
  </svg>
);

export default IconArrowDown;
