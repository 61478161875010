import React from 'react';

const IconCheckmark = ({ className }) => (
  <svg width="20" height="20" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <rect width="28" height="28" rx="2" fill="#6562FF" />
    <path d="M22 7L12.5 20L6 15" stroke="white" strokeWidth="3" />
  </svg>
);

export default IconCheckmark;
