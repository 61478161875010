import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'server/i18n';
import cx from 'classnames';

import IconClose from 'components/icons/Close';

import css from './style.css';

@withTranslation('membership')
@inject('mobxStore')
@observer
class ErrorOverlay extends Component {
  render() {
    const { data: { message }, onClose } = this.props;
    return (
      <div className={css['error-overlay']}>
        <div className={css['error-overlay-box']}>
          <p className={cx('default-l', 'regular', css['error-overlay-message'])}>
            {message}
          </p>
          <button
            className={css['error-overlay-btn']}
            onClick={() => onClose()}
          >
            <IconClose
              className={css['error-overlay-close-icon']}
            />
          </button>
        </div>
      </div>
    );
  }
}

export default ErrorOverlay;
