import React, { Component, Fragment } from 'react';
import cx from 'classnames';
import { observer, inject } from 'mobx-react';
import IconClose from 'components/icons/Close';

import style from './style.css';

@inject('mobxStore')
@observer
class DashboardModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };
    this.body = document.querySelector('body');
    this.target = null;
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  componentWillUnmount() {
    this.body.style.cssText = 'overflow: auto; position: static;';
  }

  open() {
    this.body.style.cssText = 'overflow: hidden; position: fixed;';
    this.setState({
      active: true,
    });
    if (this.props.onOpen) this.props.onOpen();
  }

  close() {
    this.body.style.cssText = 'overflow: auto; position: static;';
    this.setState({
      active: false,
    });
    if (this.props.onClose) this.props.onClose();
  }

  isOpen() {
    return this.state.active;
  }

  render() {
    const { mountOnOpen } = this.props;

    return (
      <Fragment>
        <div
          id="dashboard__modal"
          className={cx({
            [style.modalOverlay]: true,
            [style.modalActive]: this.state.active,
          })}
        >
          <div
            ref={
              (divElement) => { this.divElement = divElement; }
            }
            className={cx({
              [style.modal]: true,
              [style.modalActive]: this.state.active,
            })}
            id="dashboardModalContent"
          >
            {this.props.title && (
              <h1 className={cx('title-l', 'semi-bold', style.modalTitle)}>{this.props.title}</h1>
            )}
            {this.state.active && mountOnOpen && this.props.children}
            {!mountOnOpen && this.props.children}
            <button className={style.modalCloseIcon} onClick={this.close}>
              <IconClose
                className={style.modalCloseIconImage}
              />
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default DashboardModal;
