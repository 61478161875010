import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';

@inject('mobxStore')
@observer
export class LocalizedDate extends Component {
  constructor(props) {
    super(props);
    const {
      mobxStore: {
        country,
      },
      value,
      parseFormat,
    } = this.props;

    if (value) {
      if (moment.isMoment(value)) {
        this.moment = value;
      } else if (typeof value === 'string' || value instanceof String) {
        if (parseFormat) {
          this.moment = moment(value, parseFormat);
        } else {
          this.moment = moment(value, 'YYYY-MM-DD');
        }
      } else {
        this.moment = moment();
      }
    } else {
      this.moment = moment();
    }

    this.country = country;

    switch (this.country) {
      case 'uk':
        this.renderFormat = 'Do MMM YYYY';
        this.locale = 'en-gb';
        break;
      case 'ch':
        this.renderFormat = 'Do MMM, YYYY';
        this.locale = 'de-ch';
        break;
      case 'us':
      default:
        this.renderFormat = 'MMMM D, YYYY';
        this.locale = 'en';
        break;
    }

    this.moment.locale(this.locale);
  }

  render() {
    return (
      <span>
        { this.moment.format(this.renderFormat) }
      </span>
    );
  }
}

export function localizedDate(value, country) {
  let locale = '';
  let renderFormat = '';
  switch (country) {
    case 'us':
      locale = 'en';
      renderFormat = 'MMMM D, YYYY';
      break;
    case 'uk':
      locale = 'en-gb';
      renderFormat = 'Do MMM YYYY';
      break;
    case 'ca':
      locale = 'en-ca';
      renderFormat = 'MMM Do YYYY';
      break;
    case 'ch':
      locale = 'de-ch';
      renderFormat = 'Do MMM, YYYY';
      break;
    case 'be':
      locale = 'fr';
      renderFormat = 'LL';
      break;
    case 'cy':
      locale = 'el';
      renderFormat = 'LL';
      break;
    case 'de':
      locale = 'de';
      renderFormat = 'LL';
      break;
    default:
      locale = 'en';
  }

  const m = moment(value);
  m.locale(locale);
  return m.format(renderFormat);
}
