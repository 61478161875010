import ImmediateInAFrontRenewModal from '../immediate-in-a-front-renew-modal';

export default diff => ({
  membershipStatus:
      diff > 0
        ? { key: 'membership:memberships.renew.membership-status-days-before-due', options: { days: diff } }
        : { key: 'membership:memberships.renew.membership-status-days-past-due', options: { days: diff } },
  description: 'membership:memberships.renew.membership-status-days-before-due-description-2',
  action: ImmediateInAFrontRenewModal,
  actionLabel: 'membership:memberships.renew.membership-status-renew-now',
});
