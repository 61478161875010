import React, { Component } from 'react';
import { fetchPlaceDetails } from 'api';
import { REACT_APP_GOOGLE_AUTOCOMPLETE_KEY } from 'env';
import { initGoogleAPIsService } from 'services/google-places';
import { parseLocation } from 'utils/shipping';
import { i18n } from 'server/i18n';
import cx from 'classnames';
// import IconClose from 'components/icons/Close';

import css from './style.css';

class InputTextAutocompleteAddress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
      // isPristine: true,
      isPopulated: false,
      selectedOption: -1,
      options: [],
    };
    this.input = React.createRef();

    initGoogleAPIsService(REACT_APP_GOOGLE_AUTOCOMPLETE_KEY, () => {
      this.autocompleteService = new
      window.google.maps.places.AutocompleteService();
    });
  }

  componentDidMount() {
    const {
      field,
    } = this.props;

    this.input.current.value = field.value;

    if (this.input.current.value !== '') {
      this.setState({ isPopulated: true });
    }

    field.observe(({ change }) => {
      const fieldVal = change.newValue;
      const inputVal = this.input.current.value;
      if (inputVal !== fieldVal) {
        this.input.current.value = fieldVal;
      }
      if (!this.input.current.value) {
        this.setState({ isPopulated: false });
      }
    });
  }

  onInputChange = (event) => {
    const { value } = event.target;
    const { field } = this.props;
    field.set(value);
    clearTimeout(this.isTyping);
    this.isTyping = setTimeout(() => {
      if (value !== '') {
        this.autocompleteService.getPlacePredictions({
          componentRestrictions: {
            country: this.props.country,
          },
          types: ['address'],
          input: value,
        }, (results) => {
          this.setState({
            isActive: true,
            options: results,
            // isPristine: false,
          });
        });
      }
    }, 500);
  }

  onInputBlur = (event) => {
    const { value } = event.target;
    const { field } = this.props;
    field.set(value);

    if (value === '') {
      field.invalidate(i18n.t('forms:errors.The Address field is required', 'The Address field is required'));
    }

    this.setState({
      isActive: false,
      selectedOption: -1,
    });
  }

  selectOption = () => {
    const { selectedOption, options } = this.state;
    let value = '';
    if (selectedOption >= 0
      && selectedOption < options.length) {
      value = options[selectedOption];
      if (value.place_id) {
        this.fetchPlaceById(value.place_id, this.setInputValue);
      }
    }
    this.input.current.focus();
    this.setState({
      isActive: false,
      selectedOption: -1,
    });
  }

  selectNext = () => {
    this.setState(state => ({
      selectedOption: (state.selectedOption + 1)
        % state.options.length,
    }));
  }

  selectPrev = () => {
    this.setState(state => ({
      selectedOption: state.selectedOption <= 0
        ? state.options.length - 1
        : state.selectedOption - 1,
    }));
  }

  onTypingEvent = (event) => {
    if (!this.state.options) {
      return;
    }

    if (this.state.options.length > 0) {
      switch (event.key) {
        case 'Escape':
          this.setState({
            isActive: false,
            selectedOption: -1,
          });
          break;
        case 'ArrowDown':
          this.selectNext();
          break;
        case 'ArrowUp':
          // disables moving caret to text start position
          event.preventDefault();
          this.selectPrev();
          break;
        case 'Enter':
          if (this.state.isActive) {
            // prevents immediate form submission
            event.preventDefault();
            this.selectOption();
          }
          break;
        default:
          break;
      }
    }
  }

  setInputValue = (selectedOption) => {
    if (!selectedOption) {
      return;
    }

    const address = parseLocation(
      selectedOption.address_components,
      this.props.country,
    );
    address.address1 = `${address.streetNumber}${address.route}`;

    address.postalCode = `${address.postalCode}${address.postalCodeSuffix}`;

    this.input.current.value = `${address.streetNumber} ${address.route}, ${address.city}, ${address.state} ${address.postalCode}`;
    this.props.optionClickHandler(address);
    this.setState({ isPopulated: true });
  }

  handleResetField = () => {
    const address = {
      address1: '',
      postalCode: '',
      city: '',
      state: '',
    };
    this.props.optionClickHandler(address);
    this.input.current.value = '';
    this.input.current.focus();
    this.setState({ isPopulated: false });
  }

  setSuggestedAddress = option => (event) => {
    event.preventDefault();
    this.setState({
      isActive: false,
    });
    this.fetchPlaceById(option.place_id,
      this.setInputValue);
  }

  fetchPlaceById = (placeId, callback) => {
    fetchPlaceDetails(placeId).then(data => callback(data.result));
  }

  render() {
    const {
      field,
      id,
    } = this.props;

    const {
      isActive,
      options,
      selectedOption,
      isPopulated,
    } = this.state;

    const optional = i18n.t('forms:common.optional', 'optional');

    return (
      <div className={
        cx({
          [css['input-text-autocomplete']]: true,
          [css['input-text-autocomplete-required']]: field.rules.includes('required'),
          [css['input-text-autocomplete-hasError']]: field.error,
          [css['input-text-autocomplete-Success']]: isPopulated,
        })}
      >
        <label
          className={css['input-text-autocomplete-label']}
          htmlFor={field.id}
        >
          {
            // isPopulated
            // && (<button
            //       onClick={this.handleResetField}
            //       className={css['input-text-autocomplete-clear']}
            //     >
            //      <IconClose strokeColor="#191941" />
            //    </button>)
          }
          <span data-content={` (${optional})`} className={css['input-text-autocomplete-label-title']}>
            {i18n.t(field.label, field.label)}
          </span>
          <input
            id={id}
            type="text"
            autoComplete="address1"
            ref={this.input}
            onBlur={this.onInputBlur}
            onChange={this.onInputChange}
            onKeyDown={this.onTypingEvent}
            // disabled={isPopulated}
          />
          {
            // this is where we render the autocomplete dropdown
            options && (
              <div className={
                cx({
                  [css['input-text-autocomplete-options']]: true,
                  [css['input-text-autocomplete-options-active']]: isActive,
                })}
              >
                {
                  options.map((option, index) => (
                    <div
                      key={option.place_id}
                      className={cx({
                        [css['input-text-autocomplete-option']]: true,
                        [css.highlight]:
                          index === selectedOption,
                      })}
                      onMouseDown={this.setSuggestedAddress(option)}
                      onTouchStart={this.setSuggestedAddress(option)}
                    >
                      {option.description}
                    </div>
                  ))
                }
              </div>
            )
          }
          { this.props.field.hasError && this.props.field.error && (
            <span id={`${this.props.id}__errorMessage`} className="inputErrorMessage">
              { i18n.t(`forms:errors.${this.props.field.error.trim().replace(/[.,']/g, '')}`, this.props.field.error) }
            </span>
          )}
        </label>
      </div>
    );
  }
}

export default InputTextAutocompleteAddress;
