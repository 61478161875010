
export const parseUsLocation = (location) => {
  const parsed = {
    streetNumber: '',
    route: '',
    city: '',
    state: '',
    postalCode: '',
    postalCodeSuffix: '',
  };
  location.forEach((el) => {
    if (el.types.includes('street_number')) {
      parsed.streetNumber = `${el.long_name} `;
    }
    if (el.types.includes('route')) {
      parsed.route = el.long_name;
    }
    if (el.types.includes('locality') || el.types.includes('sublocality')) {
      parsed.city = el.short_name;
    }
    if (el.types.includes('administrative_area_level_1')) {
      parsed.state = el.short_name;
    }
    if (el.types.includes('postal_code')) {
      parsed.postalCode = el.long_name;
    }
    if (el.types.includes('postal_code_suffix')) {
      parsed.postalCodeSuffix = `-${el.long_name}`;
    }
  });

  return parsed;
};

export const parseUkLocation = (location) => {
  const parsed = {
    streetNumber: '',
    route: '',
    city: '',
    state: '',
    postalCode: '',
    postalCodeSuffix: '',
  };
  location.forEach((el) => {
    if (el.types.includes('street_number')) {
      parsed.streetNumber = `${el.long_name} `;
    }
    if (el.types.includes('route')) {
      parsed.route = el.long_name;
    }
    if (el.types.includes('locality')
    || el.types.includes('postal_town')
    || el.types.includes('sublocality')) {
      parsed.city = el.short_name;
    }
    if (el.types.includes('administrative_area_level_1')) {
      parsed.state = el.short_name;
    }
    if (el.types.includes('administrative_area_level_2')) {
      parsed.town = el.short_name;
    }
    if (el.types.includes('postal_code')) {
      parsed.postalCode = el.long_name;
    }
    if (el.types.includes('postal_code_suffix')) {
      parsed.postalCodeSuffix = `-${el.long_name}`;
    }
  });

  return parsed;
};

export const parseFrLocation = (location) => {
  const parsed = {
    streetNumber: '',
    route: '',
    city: '',
    state: '',
    postalCode: '',
    postalCodeSuffix: '',
  };
  location.forEach((el) => {
    if (el.types.includes('street_number')) {
      parsed.streetNumber = `${el.long_name} `;
    }
    if (el.types.includes('route')) {
      parsed.route = el.long_name;
    }
    if (el.types.includes('locality')
      || el.types.includes('postal_town')
      || el.types.includes('sublocality')) {
      parsed.city = el.short_name;
    }
    if (el.types.includes('administrative_area_level_1')) {
      parsed.state = el.short_name;
    }
    if (el.types.includes('administrative_area_level_2')) {
      parsed.town = el.short_name;
    }
    if (el.types.includes('postal_code')) {
      parsed.postalCode = el.long_name;
    }
    if (el.types.includes('postal_code_suffix')) {
      parsed.postalCodeSuffix = `-${el.long_name}`;
    }
  });

  return parsed;
};

export const parseDeLocation = (location) => {
  const parsed = {
    streetNumber: '',
    route: '',
    city: '',
    state: '',
    postalCode: '',
    postalCodeSuffix: '',
  };
  location.forEach((el) => {
    if (el.types.includes('street_number')) {
      parsed.streetNumber = `${el.long_name} `;
    }
    if (el.types.includes('route')) {
      parsed.route = el.long_name;
    }
    if (el.types.includes('locality')
    || el.types.includes('postal_town')
    || el.types.includes('sublocality')) {
      parsed.city = el.short_name;
    }
    if (el.types.includes('administrative_area_level_1')) {
      parsed.state = el.short_name;
    }
    if (el.types.includes('administrative_area_level_2')) {
      parsed.town = el.short_name;
    }
    if (el.types.includes('postal_code')) {
      parsed.postalCode = el.long_name;
    }
    if (el.types.includes('postal_code_suffix')) {
      parsed.postalCodeSuffix = `-${el.long_name}`;
    }
  });

  return parsed;
};

export const parseChLocation = (location) => {
  const parsed = {
    streetNumber: '',
    route: '',
    city: '',
    state: '',
    postalCode: '',
    postalCodeSuffix: '',
  };
  location.forEach((el) => {
    if (el.types.includes('street_number')) {
      parsed.streetNumber = `${el.long_name} `;
    }
    if (el.types.includes('route')) {
      parsed.route = el.long_name;
    }
    if (el.types.includes('locality')
      || el.types.includes('postal_town')
      || el.types.includes('sublocality')) {
      parsed.city = el.short_name;
    }
    if (el.types.includes('administrative_area_level_1')) {
      parsed.state = el.short_name;
    }
    if (el.types.includes('administrative_area_level_2')) {
      parsed.town = el.short_name;
    }
    if (el.types.includes('postal_code')) {
      parsed.postalCode = el.long_name;
    }
    if (el.types.includes('postal_code_suffix')) {
      parsed.postalCodeSuffix = `-${el.long_name}`;
    }
  });

  return parsed;
};


export const parseLocation = (location, country) => {
  switch (country) {
    case 'us':
      return parseUsLocation(location);
    case 'uk':
      return parseUkLocation(location);
    case 'de':
      return parseDeLocation(location);
    case 'ch':
      return parseChLocation(location);
    case 'be':
      return parseFrLocation(location);
    default:
      console.error('Unknown country:', country);
      return null;
  }
};
