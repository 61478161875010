/* eslint-disable func-names */
import axios from 'axios';
import { BACKEND_URL } from 'env';

const UK_POSTALCODE_API = 'https://api.postcodes.io/postcodes/';
const FR_POSTALCODE_API = 'https://api.postcodes.io/postcodes/';
const US_POSTALCODE_API = '/postal-codes/validate-us?';
const DE_POSTALCODE_API = 'https://api.bring.com/shippingguide/api/postalCode.json?clientUrl=localhost&country=DE&pnr=';

const validateUSPostalCodeRegex = /^\d{5}(-[0-9a-zA-Z]{4})?$/;
const validateUKPostalCodeRegex = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
const validateDEPostalCodeRegex = /^\d{5}(-[0-9a-zA-Z]{4})?$/;
const validateCHPostalCodeRegex = /^\d{5}(-[0-9a-zA-Z]{4})?$/;
const validateFRPostalCodeRegex = /^\d{5}(-[0-9a-zA-Z]{4})?$/;

const invalidCodeMessage = 'Invalid ZIP code';

export const validateUSPostalCodeSync = {
  function: postalCode => validateUSPostalCodeRegex.test(postalCode),
  message: invalidCodeMessage,
};

export const validateUSPostalCode = async function (
  postalCode,
  attribute,
  key,
  passes,
) {
  const {
    validator: {
      input,
    },
  } = this;

  const postalCodePrefix = postalCode.split('-')[0];
  try {
    // TODO before case fields unification leave this ad-hoc
    const url = `${BACKEND_URL}${US_POSTALCODE_API}code=${postalCodePrefix}&state=${input.state || input.State}`;
    const response = await axios.get(url);
    if (response.data.isValid) {
      passes();
    } else {
      passes(false, invalidCodeMessage);
    }
  } catch (error) {
    console.error(error);
    passes(false, 'Network error');
  }
};

export const validateUKPostalCodeSync = {
  function: postalCode => validateUKPostalCodeRegex.test(postalCode),
  message: invalidCodeMessage,
};

export const validateCHPostalCodeSync = {
  function: postalCode => validateCHPostalCodeRegex.test(postalCode),
  message: invalidCodeMessage,
};

export const validateUKPostalCode = async (postalCode, attribute, key, passes) => {
  try {
    await axios.get(`${UK_POSTALCODE_API}${postalCode}`);
    passes();
  } catch (error) {
    if (error.response && error.response.status === 404) {
      passes(false, 'Invalid Postal Code');
      return;
    }
    passes(false, 'Network error');
  }
};

export const validateFRPostalCode = async (postalCode, attribute, key, passes) => {
  try {
    await axios.get(`${FR_POSTALCODE_API}${postalCode}`);
    passes();
  } catch (error) {
    if (error.response && error.response.status === 404) {
      passes(false, 'Invalid Postal Code');
      return;
    }
    passes(false, 'Network error');
  }
};

export const validateFRPostalCodeSync = {
  function: postalCode => validateFRPostalCodeRegex.test(postalCode),
  message: invalidCodeMessage,
};

export const validateDEPostalCodeSync = {
  function: postalCode => validateDEPostalCodeRegex.test(postalCode),
  message: invalidCodeMessage,
};

export const validateDEPostalCode = async (postalCode, attribute, key, passes) => {
  try {
    const response = await axios.get(`${DE_POSTALCODE_API}${postalCode}`);
    if (response.data.valid) {
      passes();
    } else {
      passes(false, invalidCodeMessage);
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      passes(false, 'Invalid Postal Code');
      return;
    }
    passes(false, 'Network error');
  }
};
