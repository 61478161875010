import moment from 'moment';
import ImmediateInAFrontRenewModal from '../immediate-in-a-front-renew-modal';

export default (invoice, gracePeriod, diff) => {
  const lastServiceDay = moment(invoice.servicePeriod.serviceEndDate, 'YYYY-MM-DD').add(1, 'd');
  const preInvoicePastDue = moment().diff(moment(lastServiceDay), 'd');

  if (preInvoicePastDue === 0) {
    return {
      membershipStatus: { key: 'membership:memberships.renew.membership-status-expires-today' },
      action: ImmediateInAFrontRenewModal,
      actionLabel: 'membership:memberships.renew.membership-status-renew-now',
    };
  }

  const diffToBasic = gracePeriod - Math.abs(diff);

  if (diffToBasic >= 0) {
    return {
      membershipStatus: {
        key: 'membership:memberships.renew.membership-status-days-past-due',
        options: { days: Math.abs(diff) },
      },
      description: {
        key: `membership:memberships.renew.membership-status-days-past-due-description-${diffToBasic === 0 ? '3' : '2'}`,
        options: {
          days: diffToBasic === 0 ? '' : diffToBasic,
        },
      },
      action: ImmediateInAFrontRenewModal,
      actionLabel: 'membership:memberships.renew.membership-status-renew-now',
    };
  }

  return {
    membershipStatus: {
      key: 'membership:memberships.renew.membership-status-days-past-due',
      options: { days: Math.abs(diff) },
    },
    description: {
      key: 'membership:memberships.renew.membership-status-days-past-due-description-4',
    },
    action: ImmediateInAFrontRenewModal,
    actionLabel: 'membership:memberships.renew.membership-status-renew-now',
  };
};
