import moment from 'moment';
import ImmediateRenewModal from '../immediate-renew-modal';

export default (invoice) => {
  const invoicePastDue = moment().startOf('day').diff(moment(invoice.dueDate).startOf('day'), 'days', true);
  if (moment(invoice.servicePeriod.serviceStartDate, 'YYYY-MM-DD').isAfter(moment())) {
  // unlikely case
  // generated invoice for the future, not cover precisely
  // TODO cover this case too
    return null;
  }

  if (invoicePastDue === 0) {
    return {
      membershipStatus: { key: 'membership:memberships.renew.membership-status-expires-today' },
      action: ImmediateRenewModal,
      actionLabel: 'membership:memberships.renew.membership-status-renew-now',
    };
  }

  return {
    membershipStatus: { key: 'membership:memberships.renew.membership-status-days-past-due', options: { days: Math.abs(invoicePastDue) } },
    description: 'membership:memberships.renew.membership-status-days-past-due-description-1',
    action: ImmediateRenewModal,
    actionLabel: 'membership:memberships.renew.membership-status-renew-now',
  };
};
