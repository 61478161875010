import { UsaStates } from 'usa-states';
import {
  COUNTRY_US,
  COUNTRY_CA,
  CANADA_PROVINCES,
} from 'constants/countries';

export class States {
  static getUsaMilitaryStates() {
    return [
      {
        id: 'AE',
        value: 'AE',
        label: 'AE',
      },
      {
        id: 'AA',
        value: 'AA',
        label: 'AA',
      },
      {
        id: 'AP',
        value: 'AP',
        label: 'AP',
      },
    ];
  }

  static getUsaStateAbbreviationByName(name) {
    const stateData = new UsaStates().states
      .find(state => state.name === name);

    if (!stateData) {
      switch (name) {
        case 'Armed Forces America':
          return 'AA';
        case 'Armed Forces':
          return 'AE';
        case 'Armed Forces Pacific':
          return 'AP';
        default:
          return name;
      }
    }

    return stateData.abbreviation;
  }

  static getUsaStateByAbbreviation(abbreviation) {
    return (new UsaStates()).states.find(state => state.abbreviation === abbreviation);
  }

  static _mapStatesToOptions({ abbreviation }) {
    return {
      id: abbreviation,
      value: abbreviation,
      label: abbreviation,
    };
  }

  static createSelectOptions() {
    const USAStates = (new UsaStates()).states.map(States._mapStatesToOptions);

    return [
      { id: 'first-empty', value: '', label: '--' },
      ...USAStates,
      { id: 'last-empty', value: '', label: '--' },
      ...States.getUsaMilitaryStates(),
    ];
  }

  static createCanadaProvincesOptions() {
    return [
      { value: '', label: '--' },
      ...CANADA_PROVINCES.map(States._mapStatesToOptions),
    ];
  }

  static getStateByCountryAndAbbreviation(countryCode, stateAbbreviation) {
    switch (countryCode) {
      case COUNTRY_US.code:
        return States.getUsaStateByAbbreviation(stateAbbreviation);

      case COUNTRY_CA.code:
        return CANADA_PROVINCES.find(province => province.abbreviation === stateAbbreviation);

      default:
        return {};
    }
  }
}
