import { invoiceBalanceGteZero, invoiceBalanceEqZero } from './helpers/index';

export const getNextRenewObject = (invoice, subscription) => {
  if (invoice) {
    if (invoice.balance > 0) {
      return invoiceBalanceGteZero(invoice);
    }
    if (invoice.balance === 0) {
      return invoiceBalanceEqZero(invoice, subscription);
    }
  }
  return null;
};
